main {
  min-height: 80vh;
}

.rating span {
  margin: 0.1rem;
}

.rating svg {
  color: #f8e825;
}

@font-face {
  font-family: "PoiretOne-Regular";
  src: url("fonts/PoiretOne-Regular.ttf") format("truetype");
}

.navbar a {
  font-family: "PoiretOne-Regular", sans-serif;
  font-size: 22px;
}

.nav-link a {
  padding: 50px;
}

/*navbar dropdown menu color*/
div.dropdown-menu.show {
  background-color: #0e1b21;
}

.rating-text {
  font-size: 0.8rem;
  font-weight: 600;
  padding-left: 0.5rem;
}

.post-title {
  height: 1em; /* Set a fixed height */
  /* Hide overflow content */
  /*text-overflow: ellipsis; Add ellipsis for long text*/
  /*white-space: wrap; Prevent wrapping*/
  text-align: center;
  font-size: 20px;
  font-weight: 100;
  font-family: "PoiretOne-Regular";
  margin: 0;
}

.gpn-title {
  /* Hide overflow content */
  /*text-overflow: ellipsis; Add ellipsis for long text*/
  /*white-space: wrap; Prevent wrapping*/
  text-align: left;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 1px;
  font-family: "PoiretOne-Regular";
  margin: 10 0 0 0;
}

.gpn-text {
  text-align: left;
  font-size: 20px;
  font-family: "PoiretOne-Regular";
  margin: 0;
  letter-spacing: 1px;
}

.text-color {
  color: #ccc8b8;
}

.home-title {
  /*padding: 30px;*/
  margin: 0;
  text-align: center;
  font-size: 30px;
  font-family: "PoiretOne-Regular";
  letter-spacing: 2px;
}

.post-bg {
  /*--bs-bg-opacity: 1;
  background-color: rgba(14, 32, 56);*/
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

table td,
table th {
  text-align: center;
}

.review {
  margin-top: 30px;
}

.review h2 {
  font-size: 24px;
  background: #f4f4f4;
  padding: 10px;
  border: 1px solid #ddd;
}

.review button {
  margin-top: 10px;
}

.carousel-caption {
  background: rgba(0, 0, 0, 0.5);
}

body {
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

.card-custom {
  border-radius: none;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  padding: 14px 80px 18px 36px;
  cursor: pointer;
}

.card-custom:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px #353430, 0 4px 8px rgba(0, 0, 0, 0.06);
}

.button-custom {
  border-radius: none;
  background: none;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 0px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  padding: 5px 5px 5px 5px;
  cursor: pointer;
}

.button-custom:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px #353430, 0 4px 8px rgba(0, 0, 0, 0.06);
}

/* Default width for all screen sizes */
.custom-width-button {
  width: 100%;
}

/* Custom width for small screens and larger */
@media (min-width: 576px) {
  .custom-width-button {
    width: auto;
    min-width: 100px;
  }
}

/* Custom width for medium screens and larger */
@media (min-width: 768px) {
  .custom-width-button {
    width: auto;
    min-width: 100px;
  }
}

/* Custom width for large screens and larger */
@media (min-width: 992px) {
  .custom-width-button {
    width: auto;
    min-width: 100px;
  }
}

.accordion {
  --bs-accordion-btn-bg: #0a1214;
  --bs-accordion-btn-color: #ccc8b8;
  --bs-accordion-active-bg: #0a1214;
  --bs-accordion-active-color: #ccc8b8;
  --bs-accordion-bg: #0a1214;
  --bs-accordion-color: #ccc8b8;
}

.accordion-text {
  text-align: left;
  font-size: 17px;
  font-family: "PoiretOne-Regular";
  margin: 0;
  letter-spacing: 1px;
}

.text-color {
  color: #ccc8b8;
  font-family: "PoiretOne-Regular";
  font-size: 17px;
  letter-spacing: 1px;

}

.review-card {
  width: 100%; /* Make the card take the full width of the container */
  margin-bottom: 20px; /* Add margin between cards */
  border: none;
  background-color: transparent;
  box-shadow: none;
}

.speech-bubble {
  background-color: #ccc8b8;
  color: #0e1b21;
  padding: 15px;
  border-radius: 10px;
  position: relative;
  margin-bottom: 10px;
  display: inline-block; /* Make the bubble inline to adjust its width based on content */
}

.speech-bubble::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 20px;
  border-width: 10px 10px 0 10px;
  border-style: solid;
  border-color: #ccc8b8 transparent transparent transparent;
}

.review-text {
  margin-bottom: 0;
}

.reviewer-info {
  text-align: left;
  font-size: 17px;
  padding: 0;
  margin-bottom: 5px;
  margin-top: 5px;
}
